/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * http://drupal.org/node/1707736
 */

/* =============================================================================
   Accessibility features
   ========================================================================== */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}


%element-invisible-off { /* Turns off the element-invisible effect. */
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */

#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}

/* =============================================================================
   Main Page Regions
   ========================================================================== */

html, body {
  width: 100%;
  max-width: 100%;
  oveflow-x: hidden;
}

.region-page {
  background-position: 90% top;
  background-repeat: no-repeat;
  background-image: url(../images/bg-page.png);
}

.region-main {
  position: relative;
  background: $mainbg;
  border: 1px solid darken($mainbg, 20%);  
  .front & {
    background: $sidebarbg;
  } 
}

.region-content {
  padding-bottom: 2em;
}

.sidebar {
  background: $sidebarbg;
  border-bottom: 1px solid darken($sidebarbg, 10%);
  border-top: 1px solid darken($sidebarbg, 10%);
  .front & {
    background: $mainbg;
  }
}

/* =============================================================================
   Branding header
   ========================================================================== */

.header {
  position: relative;
}

.header--name-and-slogan { /* Wrapper for website name and slogan */
  @include breakpoint($breakpoint-medium-and-wide) {
    float: left;
  }
}

.header--site-name { /* The name of the website */
  height: 90px;
  width: 200px; /* 554 */
  margin-top: 30px;
  margin-bottom: 40px;
  position: relative;
  top: 25px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($breakpoint-medium) {
    width: 560px;
    margin-top: 30px;
  }
  @include breakpoint($breakpoint-wide) {
    width: 560px;
    margin-top: 0;
  }
}

.header--site-link { /* The link around the name of the website */
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-image: image('CPA-logo-web-narrow.svg');
  background-size: contain;
  display: block;
  height: 100%;
  width: 100%;
  .no-svg & {
    background-image: image('CPA-logo-web-narrow.png');
  }


  &:link,
  &:visited {
    color: #000;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  @include breakpoint($breakpoint-medium-and-wide) {
    background-image: image('CPA-logo-web-wide.svg');
    .no-svg & {
      background-image: image('CPA-logo-web-wide.png');
    }
  }
}

.header--site-slogan { /* The slogan (or tagline) of a website */
  @extend %element-invisible;
}

.header--region { /* Wrapper for any blocks placed in the header region */
  clear: both; /* Clear the logo */
}

.block--phone-number {
  position: absolute;
  top: 0.875em;
  right: 15em;
  color: $darkred;
  .i18n-zh-hant & {
    right: 17.5em;
  }
  a {
    text-decoration: none;
  }
  @include breakpoint($breakpoint-narrow) {
    top: 0.75em;
    p a {
      display: block;
      height: 23px;
      width: 23px;
      text-indent: -9999px;
      background-image: image("bg-phone-icon.svg");
      background-repeat: no-repeat;
    }
    .no-svg & {
      p a {
        background-image: url(../images/bg-phone-icon.png);
      }
    }
  }
}

/* =============================================================================
   Language Links
   ========================================================================== */

.region-header .block-locale {
  position: absolute;
  top: -5px;
  right: 10px;
  z-index: 10;

  .language-switcher-locale-url {
    margin: 0;
    padding: 0;
    li {
      display: inline;
    }
    .active {
      display: none;
    }
    a {
      display: inline-block;
      margin: 0;
      padding: 1em .5em .25em;
      text-decoration: none;
      font-size: 120%;
      background: $sidebarbg;
      background: rgba($sidebarbg,.7);
      border-bottom-right-radius: .25em;
      border-bottom-left-radius: .25em;
      &:hover, &:focus {
        padding-top: 1.25em;
        background: rgba($sidebarbg,.85);
      }
    }
  }
}

/* =============================================================================
   Search Form
   ========================================================================== */

.block-search {
  position: absolute;
  top: 8px;
  right: 5em;
  .i18n-zh-hant & {
    right: 6.5em;
  }
  form {
    font-size: 82.5%;
  }
  input[type="search"] {
    background: rgb(255,244,199);
    background: rgba(255,244,199,.5);
    border: 1px solid rgb(251,136,0);
  }
  input[type="submit"] {
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: image("bg-search-icon.svg");
    text-indent: -9999px;
    width: 23px;
    border:none;
    margin-left: 3px;
  }
  .no-svg & {
    input[type="submit"] {
      background-image: url(../images/bg-search-icon.png);
    }
  }
}


/* =============================================================================
   Breadcrumb navigation
   ========================================================================== */

.breadcrumb { /* The path to the current page in the form of a list of links */
  margin: 1em 0 0;
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  color: rgb(195,111,0);
  a {
    color: rgb(195,111,0);
    font-size: 68.75%;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    &:focus, &:hover {
      color: rgb(165,81,0);
      text-decoration: underline;
    }
  }
}

/* =============================================================================
   Titles
   ========================================================================== */


.sidebar header {
  overflow: hidden;
}

.sidebar .block__title {
  position: relative;
  display: inline-block;
  padding-right: 5px;
  background-color: $sidebarbg;
  .front & {
    background-color: $mainbg;
  }
  &:after {
    content: '';
    background-image: image("bg-line.png");
    background-position: left 50%; 
    background-repeat: repeat-x;
    display: block;
    width: 9999px;
    height: 2em;
    position: absolute;
    top: 0;
    left: 100%;
  }
}

.front .node {
  overflow: hidden;
}

.front .node h1 {
  position: relative;
  display: inline-block;
  padding-right: 5px;
  margin: 0.25em 0 0;
  background-color: $sidebarbg;
  &:after {
    content: '';
    background-image: image("bg-line.png");
    background-position: left 50%; 
    background-repeat: repeat-x;
    display: block;
    width: 9999px;
    height: 1.25em;
    position: absolute;
    top: 0;
    left: 100%;
  }
}

/* ==========================================================================
   Messages
   ========================================================================== */

.messages {
  margin: 1em auto 2em;
  padding: 1.5em 2em 1.5em 4.5em;
  border-width: 1px;
  border-style: solid;
  border-radius: .25em;
  background-position: 1.5em 1.25em;
  background-repeat: no-repeat;

  &.status {
    color: darken($color-status, 15%);
    background-color: lighten($color-status, 50%);
    border-color: lighten($color-status, 30%);
    @include svg-bg('message-status');
  }
  &.warning {
    color: darken($color-warning, 25%);
    background-color: lighten($color-warning, 30%);
    border-color: $color-warning;
    @include svg-bg('message-warning');
  }
  &.error {
    color: darken($color-error, 10%);
    background-color: lighten($color-error, 35%);
    border-color: darken($color-error, 5%);
    @include svg-bg('message-error');
  }
}

/* =============================================================================
   Sitewide Alert
   ========================================================================= */

.region-alert {
  padding: 1em 21px;
  background-color: #fff;
  background-color: lighten($sidebarbg, 25%);
  border-bottom: 5px solid $darkred;

  .block {
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;

    > :last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: bold;
    }
  }

  .block__title {
    margin: 0 0 .125em;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: .08em;
  }
}


/* =============================================================================
   Highlighted Region
   ========================================================================== */

.region-highlighted {
  background: rgb(128,20,20);
  border: 1px solid rgb(113,5,5);
  color: rgb(255,255,255);
  padding: 1.5em 5%;
  margin-bottom: 1.5em;
  h2, h3, h4, h5, h6, a {
    color: rgb(251,196,94);
  }
}

.front .region-highlighted {
  .block-title {
    line-height: 1.125;
    font-size: 2.5em;
    margin: 0 0 .25em;
  }
}

/* =============================================================================
   Tabs
   ========================================================================== */

.tabs-primary {
  list-style: none;
  margin: 1.5em 0;
  padding: 0;
  border-bottom: 1px solid $tabs-border;
}

.tabs-primary__tab {
  display: inline-block;
  margin: 0 0 0 .5em;
}

.tabs-primary__tab-link {
  display: block;
  position: relative;
  bottom: -1px;
  padding: .25em 1em .25em;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: .5em .5em 0 0;
  color: $tabs-link-inactive;
  &.is-active {
    color: $tabs-link-active;
    border-color: $tabs-border $tabs-border $mainbg;
    &:hover,
    &:focus {
      color: $tabs-link-active;
    }
  }
  &:hover,
  &:focus {
    color: $tabs-link-inactive-hover;
  }
}

/* =============================================================================
   Inline styles
   ========================================================================== */

.inline { /* List of links generated by theme_links() */
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; /* LTR */
  }
}

span.field-label { /* The inline field label used by the Fences module */
  padding: 0 1em 0 0; /* LTR */
}

/* =============================================================================
   "More" links
   ========================================================================== */

.more-link {
  text-align: right; /* LTR */
}

.more-help-link {
  text-align: right; /* LTR */
}

/* =============================================================================
   Pager
   ========================================================================== */

.pager { /* A list of page numbers when more than 1 page of content is available. */
  clear: both;
  padding: 0;
  text-align: center;
}

%pager-item {
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
}

.pager-item,      /* A list item containing a page number in the list of pages. */
.pager-first,     /* The first page's list item. */
.pager-previous,  /* The previous page's list item. */
.pager-next,      /* The next page's list item. */
.pager-last,      /* The last page's list item. */
.pager-ellipsis { /* A concatenation of several list items using an ellipsis */
  @extend %pager-item;
}

.pager-current { /* The current page's list item. */
  @extend %pager-item;
  font-weight: bold;
}

/* =============================================================================
   Blocks
   ========================================================================== */

.block { /* Block wrapper */
  margin-bottom: 1.5em;
}

.block-constant-contact {
  form {
    margin: -1.5em 0 2em;
  }
}

/* =============================================================================
   AddThis
   ========================================================================== */

.addthis_toolbox {
  clear: both;
  margin: 4em 0 1.5em;
}

/* =============================================================================
   Menus
   ========================================================================== */

.menu--main-menu {
  position: relative;
  clear: left;
  .menu {
    line-height: 2;
    margin: 0;
    padding: 0;
    list-style: none;
    background: $darkred; // rgb(128,19,21);
    display: inline-block;
    .menu { // second level menu
      position: absolute;
      z-index: 20;
      top: -9999px;
      width: 10.625em;
      li {
        line-height: 1.25;
        width: 100%;
        display: block;
        border-top: 1px solid darken($darkred, 5%);
      }
    }
    li {
      position: relative;
      margin: 0;
      padding: 0;
      float: left;
      list-style: none;
      display: inline-block;
      &:hover .menu {
        top: 2.5em;
      }
    }
    a {
      margin: 0;
      padding: 0;
      display: block;
      font-size: 75%;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgb(254,243,222);
      text-decoration: none;
      padding: .75em 1.5em;
      &.active-trail, &.active {
        color: rgb(245,166,49);
      }
      &:hover, &:focus {
        background-color: darken($darkred, 3%);
      }
    }
  }
}

li.menu-views:first-child {
  display:none !important; // empty li getting printed with Menu Views
}

.menu--secondary {
  .menu {
    margin-top: 0;
    padding: 0;
  }
  li {
    color: $darkred;
    margin-bottom: .5em;
    list-style-type: none;
  }
  a {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
    }
    &:before {
      content: '◦ ';
    }
    &.active {
      font-weight: bold;
      &:before {
       content: '● ';
      }
    }
  }
}

.i18n-zh-hant .menu--secondary .menu a.active {
  font-weight: normal;
}

.i18n-zh-hant .menu--secondary .menu a.active:before {
  font-size: 8px;
  padding-right: 3px;
}

/* =============================================================================
   Nodes
   ========================================================================== */

.new,
.update { /* The "new" or "updated" marker. */
  color: #c00;
  background-color: transparent; /* Remove background highlighting from <mark> in normalize. */
}

/* =============================================================================
   Unpublished note
   ========================================================================== */

.unpublished { /* The word "Unpublished" displayed underneath the content. */
  height: 0;
  overflow: visible;
  background-color: transparent; /* Remove background highlighting from <mark> in normalize. */
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word; /* A very nice CSS3 property */
}

/* =============================================================================
   Comments
   ========================================================================== */

.comments { /* Wrapper for the list of comments and its title */
  margin: 1.5em 0;
}

.comment-preview { /* Preview of the comment before submitting new or updated comment */
  background-color: #ffffea; /* Drupal core will use a #ffffea background. See #1110842 */
}

.comment { /* Wrapper for a single comment */

  .permalink { /* Comment's permalink wrapper */
    text-transform: uppercase;
    font-size: 75%;
  }
}

.indented { /* Nested comments are indented */
  margin-left: $indent-amount; /* Drupal core uses a 25px left margin */
}

/* =============================================================================
   Forms
   ========================================================================== */

.form-item { /* Wrapper for a form element (or group of form elements) and its label */
  margin: 1.5em 0;

  .form-checkboxes & ,
  .form-radios & { /* Pack groups of checkboxes and radio buttons closer together */
    margin: 0; /* Drupal core uses "0.4em 0" */
  }

  tr.odd &,
  tr.even & { /* Form items in a table. */
    margin: 0;
  }

  input.error,
  textarea.error,
  select.error { /* Highlight the form elements that caused a form submission error */
    border: 1px solid #c00;
  }

  .description { /* The descriptive help text (separate from the label) */
    font-size: 0.85em;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

.form-required { /* The part of the label that indicates a required field */
  color: #c00;
}

label.option {
  display: inline;
  font-weight: normal;
}

.password-parent,
.confirm-parent { /* Password confirmation */
  margin: 0;
}

#user-login-form { /* Drupal's default login form block */
  text-align: left; /* LTR */
}

.webform-client-form {
  margin-bottom: 2em;
}

form .description {
  font-style: italic;
  font-size: 87.5%;
  padding-top: .25em;
}

input:not([type="submit"]) {
  padding: .375em .5em;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  border: 1px solid darken($mainbg, 5%);
  max-width: 100%;
}

textarea {
  font-family: inherit;
  font-size: 100%;
  padding: .375em .5em;
  color: inherit;
  line-height: inherit;
  border: 1px solid darken($mainbg, 5%);
}

input[type="file"] {
  font-size: 100%;
  padding: .375em .5em;
  border: 1px solid darken($mainbg, 5%);
}

input[type="submit"] {
  font-size: 95%;
  padding: .5em .625em;
  background: rgb(128,19,21);
  color: rgb(255,255,255);
  border: none;
  border-radius: .25em;
}

/**
 * qgiv-embed-container
 */
.qgiv-embed-container {
  background-color: #fff;
  margin-bottom: 1.5em !important;
  max-width: 100% !important;
}

/*
 * Drupal admin tables
 */

form {
  th {
    text-align: left; /* LTR */
    padding-right: 1em; /* LTR */
    border-bottom: 3px solid #ccc;
  }

  tbody {
    border-top: 1px solid #ccc;
  }

  table ul {
    margin: 0;
  }
}

tr.even,
tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}

tr.even {
  background-color: #fff;
}

td.active { /* Markup generated by theme_tablesort_indicator(). */
  background-color: #ddd;
}

td.checkbox,
th.checkbox {
  text-align: center;
}

/*
 * Drupal core wrongly puts this in system.menus.css. Since we override that, add it back.
 */

td.menu-disabled {
  background: #ccc;
}


/* =============================================================================
   Footer Region
   ========================================================================== */


.region-footer {
  padding-top: 1em;
  .vcard {
    margin: 1em 0;
    font-size: 75%;
    .org {
      font-size: 110%;
      font-weight: bold;
      color: $darkred;
    }
    .email {
      display: block;
    }
    .type {
      @extend %element-invisible;
    }
  }
}

.links--social {
  @include breakpoint($breakpoint-wide) {
    position: absolute;
    top: -9px;
    right: 23em;

    .i18n-zh-hant & {
      right: 25em;
    }
  }
  li {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-left: .25em;
  }
  a {
    text-indent: -9999px;
    display: block;
    height: 32px;
    width: 32px;
  }
}


/* =============================================================================
   Home Page
   ========================================================================== */

.views_slideshow_cycle_slide, 
#views_slideshow_cycle_teaser_section_home_page_photos-block, 
#views_slideshow_cycle_teaser_section_home_page_photos-block img /* Slide Show */ {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

/* =============================================================================
   Default Field Styles
   ========================================================================== */

.region-page {
  img, iframe {
    border: $img-border-width solid #fff;
    box-shadow: 0 0.25em .25em #999;
  }
}

.feed-icon img,
form img {
  border: none !important;
  box-shadow: none !important;
}


.date,
.field-event-date {
  color: rgb(177,121,1);
  font-size: 87.5%;
}

.field-program-short-description {
  font-size: 87.5%;
}

.field-program-photo {
  .node-program & {
    margin-bottom: 1.5em;
  }
}

/* =============================================================================
   Links
   ========================================================================== */

%button {
  text-decoration: none;
  display: inline-block;
  padding: .625em .75em .5em;
  margin: 0 .5em 1em 0;
  line-height: 1.25;
  color: #fff;
  background-color: $darkred;
}

a.button {
  @extend %button;
}

/* home page welcome message */
.block--home-welcome,
.front .region-highlighted .block.last {
  a.button {
    padding: .75em 1.5em;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: .875em;
    &:nth-child(1) {
      background: rgb(227,155,0);
    }
    &:nth-child(2) {
      background: rgb(212,133,1);
    }
    &:nth-child(3) {
      background: rgb(193,103,2);
    }
  }
}


/* =============================================================================
   Teaser List
   ========================================================================== */

.teaser-list {
  article {
    margin-bottom: 1.5em;
  }
  h2, h3 {
    line-height: 1.25;
  }
  h2 {
    margin: .5em 0;
  }
  h3 {
    margin: 0;
  }
  .date, 
  .field-event-date {
    margin: 0;
  }
  .field-short-overview {
    font-size: 87.5%;
  }
}

.field-related-programs {
  @extend .inline;
}

h3.field-label {
  margin-bottom: 0;
}


/* =============================================================================
   Programs
   ========================================================================== */

.view-programs.view-display-id-page,
.view-programs.view-display-id-page_1 {
  article {
    @include breakpoint($breakpoint-wide) {
      float: left;
      width: 30%;
      margin-right: 3%;
      margin-bottom: 1.5em;
      &:nth-child(3n+4) {
        clear: left;
      }
    }
  }
}

/* =============================================================================
   Events
   ========================================================================== */

.node-type-event {
  .field-event-date {
    font-size: 1.25em;
    margin-bottom: 0.5em;
  }
  .field-event-address {
    margin: 0.5em 0 1.5em;
    font-size: 1.25em;
    line-height: 1.25;
    color: #b17901;
  }
}

