/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;} 

/* FlexSlider Necessary Styles
*********************************/ 
.flexslider {margin: 0; padding: 0;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider { 
	zoom: 1; 
	position: relative; 
	overflow: hidden;
	.slides {
		zoom: 1;
	}
}
.flex-viewport {
	max-height: 2000px; 
	transition: all 1s ease;
	.loading & {
		max-height: 300px;
	}
}


/* Direction Nav */
.flex-direction-nav {
	*height: 0;
	a {
		width: 30px; 
		height: 30px;
		margin: -20px 0 0; 
		display: block; 
		position: absolute;
		top: 50%; 
		z-index: 10;
		cursor: pointer; 
		text-indent: -9999px;
		background: #fff;
		color: $darkred; 
		opacity: 0.8; 
		transition: all 0.3s ease;
		&:hover, &:focus {
			opacity: 1;
		}
		&:after {
			text-indent: 0;
			top: 0;
			width: 30px;
			text-align: center;
			font-size: 20px;
			line-height: 1.5;
			color: $darkred; 
			display: block;
			position: absolute;
		}
	}
	.flex-next {
		right: $img-border-width;
		&:after {
			content: '>';
		}
	}
	.flex-prev {
		left: $img-border-width;
		&:after {
			content: '<';
		}
	}
	.flex-disabled {
		opacity: .3!important; 
		filter:alpha(opacity=30);
		cursor: default;
	}
}

/* Caption */
.flex-caption {
	zoom: 1;
	width: auto; 
	max-width: 100%; 
	padding: .75em 1.5em; 
	margin: 0; 
	position: absolute; 
	left: 0; 
	bottom: 0; 
	background: rgb(0,0,0); 
	background: rgba(0,0,0,.7); 
	color: #fff; 
	text-shadow: 0 -1px 0 rgba(0,0,0,.3); 
	font-size: 1em;
}
.slides li {
	.flex-caption {
		bottom: -6em;
		left: $img-border-width;
		transition: all 0.6s ease;
	}
	&:hover .flex-caption {
		bottom: $img-border-width;
	}
}

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline;}
.flex-control-paging li a {width: 11px; height: 11px; display: block; background: #666; background: rgba(0,0,0,0.5); cursor: pointer; text-indent: -9999px; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; box-shadow: inset 0 0 3px rgba(0,0,0,0.3);}
.flex-control-paging li a:hover { background: #333; background: rgba(0,0,0,0.7); }
.flex-control-paging li a.flex-active { background: #000; background: rgba(0,0,0,0.9); cursor: default; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {opacity: 1; left: 0;}
  .flex-direction-nav .flex-next {opacity: 1; right: 0;}
}