/**
 * @file
 * Positioning for responsive layout .
 *
 */



/*
 * Center the page.
 */

.region-page,
.region-bottom {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  position: relative;
}

// Region Containers 
.region-main,
.header {
  position: relative;
  @include clearfix;
}

.header, 
.region-highlighted, 
.region-content, 
.region-sidebar-first, 
.region-sidebar-second, 
.region-footer {
  padding-left: 21px;
  padding-right: 21px;
}


/**
 * Home Layout 
 */
.front {
  .region-main {
    @include breakpoint($breakpoint-medium) {
      margin-top: 3.5em;
    }
    @include breakpoint($breakpoint-wide) {
      margin-top: 5.5em;
    }
  }

  .region-content {
    @include breakpoint($breakpoint-medium) {
      float: left;
      width: 66.66%;
    }
    @include breakpoint($breakpoint-wide) {
      float: left;
      width: 60%;
    }
  }

  .region-highlighted {
    position: relative;
    z-index:10;
    @include breakpoint($breakpoint-medium-and-wide) {
      left: -5%;
      width: 110%;
    }
    @include breakpoint($breakpoint-medium) {
      // @include zen-grid-item(2, 1);
      top: -3.5em;
      margin-bottom: -2.5em;
    }
    @include breakpoint($breakpoint-wide) {
      // @include zen-grid-item(4, 1);
      top: -5.5em;
      margin-bottom: -4em;
    }
  }
  .node {
    clear: both;
    @include breakpoint($breakpoint-wide) {
      & ~ .block {
        width: 45%;
        float: left;
        margin-right: 4.75%;
      }
    }
  }
  .region-sidebar-first {
    @include breakpoint($breakpoint-medium-and-wide) {
      padding-left: 31px;
    }
  }

  .sidebars {
    @include breakpoint($breakpoint-medium) {
      float: right;
      width: 33.33%;
    }
    @include breakpoint($breakpoint-wide) {
      float: right;
      width: 40%;
    }
  }
}


/**
 * Default Layout 
 */

.not-front {
  .region-content {
    @include breakpoint($breakpoint-medium) {
      float: left;
      width: 66.6%;
    }
    @include breakpoint($breakpoint-wide) {
      float: left;
      width: 60%;
    }
  }

  .sidebars {
    @include breakpoint($breakpoint-medium) {
      float: right;
      width: 33.3%;
    }
    @include breakpoint($breakpoint-wide) {
      float: right;
      width: 40%;
    }
  }
}

/**
 * No Sidebars
 */
.no-sidebars {
  .region-content {
    @include breakpoint($breakpoint-medium-and-wide) {
      float: left;
      width: 80%;
    }
  }
}
