//
// "base" partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "base" declaration. And this _base.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This base partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//

/**
 * Breakpoint
 * @link https://github.com/at-import/breakpoint
 */
 @import '../node_modules/breakpoint-sass/stylesheets/breakpoint';


// =============================================================================
// Variables
// =============================================================================

//
// Legacy IE support
//
// These variables are used by many mixins to add additional CSS to support
// specific versions of IE or specific vendor prefixes.
//
// IE6-7 don't support box-sizing: border-box. We can fix this in 1 of 3 ways:
// - Drop support for IE 6/7. :-) Set $legacy-support-for-ie6
//   and $legacy-support-for-ie7 to false.
// - (Preferred) Install the box-sizing polyfill and set the variable below to
//   the absolute path URL to the boxsizing.htc file.
//   @see https://github.com/Schepp/box-sizing-polyfill
// $box-sizing-polyfill-path: "/sites/all/themes/cpa/polyfills/box-sizing-polyfill/boxsizing.htc";
// - Use the same CSS unit for grid and gutter width in resonsive-sidebars.scss
//   (use px for both or use % for both) and set the box-sizing variable to content-box.
//
// Zen does not require special handling for IE8 or later. But Compass uses that
// variable for a couple edge cases. We include it for completeness sake. See
// the documentation at http://compass-style.org/reference/compass/support/
// $legacy-support-for-ie6: false;
//$legacy-support-for-ie7: false;
// $legacy-support-for-ie8: true;


//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   15px; // The font size set on the root html element.
$base-line-height: 20px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.5  * $base-font-size;
$h3-font-size: 1.17 * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// Font Stacks

$sans: 	"proxima-nova-1","proxima-nova-2", sans-serif;
$sans-condensed: 	"proxima-nova-condensed-1", "proxima-nova-condensed-2", sans-serif;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $sans; // The font family set on the html element.
$font-body:        $sans;
$font-monospace:   $courier;


//
// Colors, etc.
//

$darkgray: rgb(50,50,50);
$darkred: rgb(129,6,6);
$mediumorange: rgb(253,173,0); 

$body-color: $darkgray;
$link-color: $darkred;
$bodybg: $mediumorange;
$mainbg: rgb(250,209,129);
$sidebarbg: rgb(251,196,94);


// The amount lists, blockquotes and comments are indented.
$indent-amount: 1em;

// Border image width
$img-border-width: 5px;


// Tab styling.
$tabs-link-active: $body-color;
$tabs-link-inactive: $link-color;
$tabs-link-inactive-hover: $link-color;
$tabs-border: rgba($body-color, .3);


//
// Breakpoints
//
@include breakpoint-set('to ems', true);
@include breakpoint-set('no query fallbacks', true);


$narrow-end:  	600px;
$middle-start: 	601px;
$middle-end: 	959px;
$wide-start: 	960px;

$breakpoint-narrow: max-width $narrow-end;
$breakpoint-medium: $middle-start $middle-end;
$breakpoint-wide: $wide-start, 'no-query' '.no-mq';
$breakpoint-medium-and-wide: $middle-start, 'no-query' '.no-mq';

// Messages
$color-status                 : rgb(76,157,42);
$color-warning                : rgb(250,220,59);
$color-error                  : red;



// =============================================================================
// Custom sass mixins
// =============================================================================

// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

/**
 * Clearfix
 */

@mixin clearfix {
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

/* Convert PX units to EMs.
Ex: margin-right: pem(16);
*/
@function pem($pxval, $base: 16) {
  @if (unitless($pxval)) {
    $pxval: $pxval * 1px;
  }

  @if (unitless($base)) {
    $base: $base * 1px;
  }

  @return $pxval / $base * 1em;
}

// element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
%element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
%element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}


// Background-size cover
@mixin background-size-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;  /* Firefox 3.6 */
  background-size: cover;
  background-position: center;  /* Internet Explorer 7/8 */
}

/**
 * SVG Bckground Images with png fallback
 * @param {String} $filename
 * @example scss
 * .foobar { @include svg-bg('image') { ... } }
 */

// modernizr class and Sass content inject for fallback code
@mixin no-svg { .no-svg & { @content } }
 
// mixin that requires filename without path or extension and optional extension override
@mixin svg-bg($filename, $extension: '.png') {
  background-image: image($filename + '.svg');
  @include no-svg { background-image: image($filename + $extension); }
}

// ==========================================================================
// Assets
// ==========================================================================

/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: '..' !default;

/// Asset URL builder
/// @access private
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
@function asset($type, $file) {
  @return url($asset-base-path + '/' + $type + '/' + $file);
}

/// Image asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
@function image($file) {
  @return asset('images', $file);
}

/// Font asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the font
/// @require {function} asset
@function font($file) {
  @return asset('fonts', $file);
}


